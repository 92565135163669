import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css'
import Carousel from './component/Carousel';
export default function WhyHere() {
    AOS.init();
    return (
        <div className='why_here_main'>
            <div className='why_head'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h1>Why you need to be here ?</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='carousel'>
                <Carousel/>
            </div>

        </div>

    )
}
