import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'
import banner from './banner.jpg';
import logo from './logo.png';
import WhyHere from './WhyHere';
import './App.css';
import SecondPart from './component/SecondPart';
import Bottom from './component/Bottom';
import Host from './component/Host';
import { Link, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import Trail from './component/CenterText.tsx';
import styles from './component/centertextstyles.module.css'
import Speciality from './component/third.tsx'
import HowToReach from './component/HowToReach.js';
import ImageGallery from './component/imageGallery.js'
import upArrow from './assets/image/upArrow.png' 

function App() {
	const [open, set] = useState(true)
	const [showButton, setShowButton] = useState(false);
	useEffect(() => {
		Events.scrollEvent.register('begin', (to, element) => {
			console.log('begin', to, element);
		});
		Events.scrollEvent.register('end', (to, element) => {
			console.log('end', to, element);
		});
		scrollSpy.update();
		scrollToTop()
		return () => {
			Events.scrollEvent.remove('begin');
			Events.scrollEvent.remove('end');
		};
	}, []);

	AOS.init({});
	const handleScroll = () => {
		if (window.scrollY > 300) {
		  setShowButton(true);
		} else {
		  setShowButton(false);
		}
	  };
	const scrollToTop = () => {
		scroll.scrollToTop();
	};
	const scrollToBottom = () => {
		scroll.scrollToBottom();
	};
	const handleSetActive = (to) => {
		// console.log(to);
	};
	scrollToTop()
	return (
		<div className="App" style={{'overflow':'hidden'}}>
			<nav className="navbar navbar-expand-lg navbar-light bg-light nav_main z-1">
				<div className="container-fluid">
					<a className="navbar-brand" onClick={scrollToTop}><img src={logo} resizemode='contain' style={{ height: 60, width: 190 }}></img></a>
					<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse nav_link " id="navbarText">
						<ul className="navbar-nav">
							<li className="nav-item">
								<a className="nav-link">
									<Link
										to="about"
										spy={true}
										smooth={true}
										offset={50}
										duration={300}
										onSetActive={handleSetActive}
									>
										Exquisite Escape
									</Link>
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link">
									<Link
										to="whyhere"
										spy={true}
										smooth={true}
										offset={50}
										duration={300}
										onSetActive={handleSetActive}
									>
										Why be here ?
									</Link>
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" >
									<Link
										to="host"
										spy={true}
										smooth={true}
										offset={50}
										duration={300}
										onSetActive={handleSetActive}
									>
										Our Guarantee
									</Link>
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link">
									<Link
										to="contact"
										spy={true}
										smooth={true}
										offset={0}
										duration={300}
										onSetActive={handleSetActive}
									>
										Reserve Your Spot
									</Link>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>
			<div name='begin' className="banner_main">
				<div className='overflow-hidden'>
					<figure>
						<img src={banner} alt="banner" className="banner-image full zoom " />
					</figure>
				</div>
				<div className="banner_content">
					<div className='about_banner'>
						<div >
							<div className={styles.container} onClick={() => set(state => !state)}>
								<Trail open={open}>
									<span className='banner-text'>Your Oasis by Ashtamudi Lake</span>
									<p>An Exquisite Escape Awaits.</p>
								</Trail>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div name='about'>
				<SecondPart />
			</div>
			<div name='speciality'>
				<Speciality />
			</div>
			<div name='whyhere'>
				<WhyHere />
			</div>
			<div>
				<ImageGallery />
			</div>
			<div name='host'>
				<Host />
			</div>
			<div name='how to reach'>
				<HowToReach />
			</div>
			<div name='contact'>
				<Bottom />
			</div>
			
			<Link className='totop' onClick={scrollToTop}>
					<img src={upArrow} alt='image' style={{ height: 25 , width : 25}} />
			</Link>
		</div>
	);
}

export default App;
