import React, { useState } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css'
import locationicn from '../assets/image/location.png'
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let toDay = new Date().toISOString().split('T')[0]; // set date in YYYYM-MM-DD format with toISOString() method

const formInitialValue = {
    name: '',
    email: '',
    country: '',
    state: '',
    city: '',
    phone: '',
    fromdate: toDay,
    todate: toDay
}

function Bottom() {
    const [formObj, setFormObj] = useState(formInitialValue);
    const [showForm, setShowForm] = useState('')
    const [missingField, setMissingFiled] = useState('')
    AOS.init({
        duration: 1000,
    });

    const InputChange = (e) => {
        setFormObj({ ...formObj, [e.target.name]: e.target.value })
    }
    const isValidEmail = () => {
        const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return validRegex.test(formObj.email);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        let validEmail = isValidEmail()
        if (!formObj.name || !formObj.email || !formObj.country || !formObj.state || !formObj.city || !formObj.phone) {
            setMissingFiled('Please fill in all fields.')
            return
        }
        if (!validEmail) {
            return
        }

        const formData = new FormData();
        for (let key in formObj) {
            formData.append(key, formObj[key]);
        }
        const headers = {
            'Content-Type': 'multipart/form-data',
        };
        let res = await axios.post('https://tranquilthaamara.com/api/post_handler.php', formData, {
            headers: headers
        })
        if (res?.status === 200) {
            setShowForm(false)
            toast.success('Thank you for your request. Your request sent successfully.')
        } else {
            toast.error('Something went wrong. Please try again later.')
        }
        setFormObj({ ...formInitialValue })
        setMissingFiled('')
    };

    const isValidPhoneNumber = () => {
        const phonePattern = /^\d{10}$/;
        return phonePattern.test(formObj.phone);
    };
    return (
        <div>
            <div className='contact_main'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='contact_head'>
                                <ToastContainer position='top-center' reverseOrder={false} />
                                <h2>Reserve Your Spot in Paradise</h2>
                                <p>The door to your getaway at <span style={{fontWeight:900, color:'#000000'}}>Tranquil Thaamara</span> is wide open. Secure your stay and embark on a journey where luxury, tranquility, and Kerala's vibrant culture intertwine. We invite you to indulge in a lakeside retreat like no other.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='px-5 add_map_main'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-12'>
                            <div className='location'>
                                <div data-aos="zoom-in-down" data-aos-offset="100" data-aos-delay="250"
                                    data-aos-duration="1200" className='location_detail'>
                                    <img src={locationicn}></img>
                                    <h4>Location</h4>
                                    <p>Ashtamudi Lake, Kollam, Kerala</p>
                                    <h5>Name - Harshal Badodkar<br />
                                        Email :  info@tranquilthaamara.com<br />
                                        Mobile: 93028-57824, 9895299075
                                    </h5>
                                    <div className='justify-content-center d-flex text-center send-request'>
                                        <button style={{ backgroundColor: "rgb(55,112,68)", borderWidth: 0 }} type="button" className="btn btn-primary justify-content-center d-flex" onClick={() => setShowForm(true)}>
                                            Send your booking request
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12'>
                            <div className='map_main'>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126117.90033053231!2d76.51847782283926!3d8.95515108851029!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05fdbb69f5a623%3A0xa28e3beb592ce94b!2sAshtamudi%20Lake!5e0!3m2!1sen!2sin!4v1699251663101!5m2!1sen!2sin" width="90%" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {showForm &&
                    <div className="modal fade show" tabIndex="-1" style={{ display: 'block' }} aria-modal="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <form action="" className='flex flex-col w-full md:w-1/2  '>
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5"> Send your booking request</h1>
                                        <button type="button" className="btn-close" onClick={() => setShowForm(false)} ></button>
                                    </div>
                                    <div className="modal-body">

                                        <div className="mb-1">
                                            <label htmlFor="recipient-name" className="col-form-label">Name</label>
                                            <input type="text" name='name' maxLength={15} onChange={(e) => InputChange(e)} value={formObj.name} className="form-control" id="recipient-name" required />
                                            {
                                                formObj?.name?.length == 0 ? " " : formObj?.name?.length == 30 &&
                                                    <p className='text-danger'> Name reached at max character </p>
                                            }
                                        </div>
                                        <div className="mb-1">
                                            <label htmlFor="recipient-name" className="col-form-label">Email:</label>
                                            <input type="email" name='email' onChange={(e) => InputChange(e)} value={formObj.email} className="form-control" />
                                            {formObj?.email?.length == 0 ? " " : !isValidEmail() &&
                                                <p className='text-danger'> Please Enter Valid Email</p>
                                            }
                                        </div>
                                        <div className="mb-1">
                                            <label htmlFor="recipient-name" className="col-form-label">Country:</label>
                                            <input type="text" name='country' onChange={(e) => InputChange(e)} value={formObj.country} className="form-control" />
                                        </div>
                                        <div className="mb-1">
                                            <label htmlFor="recipient-name" className="col-form-label">State:</label>
                                            <input type="text" name='state' onChange={(e) => InputChange(e)} value={formObj.state} className="form-control" />
                                        </div>
                                        <div className="mb-1">
                                            <label htmlFor="recipient-name" className="col-form-label">City:</label>
                                            <input type="text" name='city' onChange={(e) => InputChange(e)} value={formObj.city} className="form-control" />
                                        </div>
                                        <div className="mb-1">
                                            <label htmlFor="recipient-name" className="col-form-label">Phone:</label>
                                            <input type="text" name='phone' maxLength={10}
                                                onChange={(e) => InputChange(e)}
                                                value={formObj.phone}
                                                className="form-control" required />
                                            {
                                                formObj?.phone?.length == 0 ? " " : !isValidPhoneNumber() &&
                                                    <p className='text-danger'> Invalid phone number type </p>
                                            }
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="fromdate">From Date:</label>
                                                <input type="date" onChange={(e) => InputChange(e)} value={formObj.fromdate} className="form-control" id="fromdate" name="fromdate" />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="todate">To Date:</label>
                                                <input type="date" value={formObj.todate} onChange={(e) => InputChange(e)} className="form-control" id="todate" name="todate" />
                                            </div>
                                        </div>
                                        <div>
                                            {missingField.length > 0 &&
                                                <p className='text-danger'> {missingField}</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" onClick={() => setShowForm(false)}>Close</button>
                                        <button id="submit_button" type="button" onClick={(e) => handleSubmit(e)} className="btn btn-primary">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className='footer'>
                <p className='mb-0'>© Copyright <span>Tranquil Thaamara.</span> All Rights Reserved</p>
            </div>


        </div>

    )
}

export default Bottom