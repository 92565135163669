import React from 'react';
import Slider from "react-slick";
import img1 from '../image_gallery/File_1.jpg';
import img2 from '../image_gallery/File_2.jpg';
import img3 from '../image_gallery/File_3.jpg';
import img4 from '../image_gallery/File_4.jpg';
import img5 from '../image_gallery/File_5.jpg';
import img6 from '../image_gallery/File_6.jpg';
import img7 from '../image_gallery/File_7.jpg';
import img8 from '../image_gallery/File_8.jpg';
import img9 from '../image_gallery/File_9.jpg';
import img10 from '../image_gallery/File_10.jpg';
import img11 from '../image_gallery/File_11.jpg';
import img12 from '../image_gallery/File_12.jpg';
import img13 from '../image_gallery/File_13.jpg';
import img14 from '../image_gallery/File_14.jpg';
import img15 from '../image_gallery/File_15.jpg';
import img16 from '../image_gallery/File_16.jpg';
import img17 from '../image_gallery/File_17.jpg';
import img18 from '../image_gallery/File_18.jpg';
import img19 from '../image_gallery/File_19.jpg';
import img20 from '../image_gallery/File_20.jpg';
import img21 from '../image_gallery/File_21.jpg';
import img22 from '../image_gallery/File_22.jpg';
import img23 from '../image_gallery/File_23.jpg';
import img24 from '../image_gallery/File_24.jpg';
import img25 from '../image_gallery/File_25.jpg';
import img26 from '../image_gallery/File_26.jpg';
import img27 from '../image_gallery/File_27.jpg';

const Images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21, img22, img23, img24, img25, img26, img27]
function ImageGallery() {
    var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    return (
        <div className='why_here_main'>
            <div className='why_head'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h1>Image Gallery</h1>
                        </div>
                    </div>
                </div>
            </div>
            <Slider {...settings}>
            {Images.map((item, index) => (
                    <div key={index} className="col-md-3 col-sm-12 slider_element">
                        <div className="gallery_image">
                            <img className="mx-auto" src={item} alt={index} />
                        </div>
                    </div>
                ))}
        </Slider>
        </div>
    )
}

export default ImageGallery