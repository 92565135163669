import React, { useEffect } from 'react'
import AOS from 'aos';
import './carousel.css';
import img1 from '../assets/image/DSC08712.jpg'
import modern from '../assets/image/DSC08458.jpg'
import kollam from '../assets/image/kollam.jpg'
import nature from '../assets/image/DSC08513.jpg'
import organic from '../assets/image/DSC08674.jpg'

export default function Speciality() {
    AOS.init();
    return (
        <div className='why_here_main'>

            <div className='container'>
                <div className='row'>
                    <div className="col-md-6 slider_element">
                        <div className="speciality">
                        <img src={nature} className="mx-auto img-fluid"
                                style={{ width: '100%', height: '90%' }} />
                                <p className="thing_to_do_title ">Nature's Embrace</p>
                            <p>We believe that nature holds the power to heal, inspire, and rejuvenate. Our concept focuses on creating an environment where guests can connect with nature, savor its tranquility, and experience a profound sense of inner calm.</p>
                        </div>
                    </div>
                    <div className="col-md-6 slider_element">
                        <div className="speciality">
                            <div>
                                <img src={organic} className="mx-auto img-fluid"
                                style={{ width: '100%', height: '90%' }} />
                                <p className="thing_to_do_title  fw-bolder">Organic Harmony</p>
                                <p> <span style={{fontWeight:900, color:'#000000'}}>Tranquil Thaamara</span> is a place where the sounds of lapping lake waters, the gentle rustling of coconut palms, and the melodies of native birds become an orchestra of serenity. We encourage guests to embrace this organic symphony and allow it to refresh their spirits.
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-6 pt-4 slider_element">
                        <div className="speciality">
                            <img src={modern} className="mx-auto img-fluid"
                                style={{ width: '100%', height: '90%' }} />
                                <p className="thing_to_do_title  fw-bolder">Modern Comfort</p>
                            <p>We seamlessly blend modern amenities with the grace of the natural world. Our 2-bedroom villa offers the convenience of home within an environment of lush gardens and serene water views.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 pt-4 slider_element">
                        <div className="speciality">
                            <img src={kollam} className="mx-auto img-fluid"
                                style={{ width: '99%', height: '60%' }} />
                            <p className="thing_to_do_title  fw-bolder">Gateway to Kollam's Wonders</p>
                            <p> <span style={{fontWeight:900, color:'#000000'}}>Tranquil Thaamara</span> also serves as a gateway to explore the captivating attractions of Kollam, including its cultural richness, vibrant markets, historic sites, and breath-taking natural beauty.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}