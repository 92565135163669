import React from 'react';
import boat from '../assets/image/boat.png';
import island from '../assets/image/island.png';
import beach from '../assets/image/beach.png';
import temple from '../assets/image/temple.png';
import waterfall from '../assets/image/waterfall.png';
import advent from '../assets/image/advent.png';
import wetland from '../assets/image/wetland.png';
import tour from '../assets/image/tour.png';
import './carousel.css';
import Tilt from 'react-parallax-tilt';

const Carousel = () => {
    const carouselItems1 = [
        {
            title: 'Backwater Cruises',
            description:
                'Explore the serene and picturesque backwaters of Lake by taking a houseboat or a traditional Kettuvallam cruise. Enjoy the tranquil surroundings, lush greenery, and observe the local life along the waterways.',
            image: boat,
        },
        {
            title: 'Munroe Island',
            description:
                'Visit Munroe Island, located at the confluence of Lake and Kallada River. It offers a unique experience of exploring the backwaters, paddy fields, and coconut groves. You can take a canoe ride, visit the coir-making units, and interact with the locals.',
            image: island,
        }, {
            title: 'Ashtamudi Wetland',
            description:
                'Discover the rich biodiversity of the Ashtamudi Wetland, which is a Ramsar site and home to numerous species of birds, fish, and other aquatic life. You can go birdwatching, take a boat tour, or even try fishing (with permission and following local regulations).',
            image: wetland,
        }, {
            title: 'Thangasseri Beach',
            description:
                'Head to Thangasseri Beach, located about 9 kilometers from Astamudi Lake. Enjoy the tranquil beach, stroll along the promenade, and explore the historical attractions like Thangasseri Lighthouse and the remnants of Portuguese and Dutch forts.',
            image: beach,
        },
    ];
    const carouselItems2 = [
        {
            title: 'Adventure Sports',
            description:
                "Engage in some thrilling activities near Lake. You can try kayaking, canoeing, or even windsurfing in the lake's calm waters. Several operators in the area offer these adventure sports.",
            image: advent,
        }, {
            title: 'Palaruvi Waterfalls',
            description:
                'Take a day trip to Palaruvi Waterfalls, located around 75 kilometers. Enjoy the cascading waterfall, take a refreshing dip in the pool, and revel in the natural beauty of the surroundings.',
            image: waterfall,
        }, {
            title: 'Kollam City Tour',
            description:
                'Explore the nearby city of Kollam, which is known for its historical and cultural significance. Visit attractions like the Kollam Beach, Thirumullavaram Beach, Portuguese-built Tangasseri Church, and the iconic Mahatma Gandhi Beach and Park.',
            image: tour,
        }, {
            title: 'Visit Temples',
            description:
                'Astamudi Lake is surrounded by several temples of religious importance. You can visit the Sasthamkotta Temple, dedicated to Lord Ayyappa, or the Oachira Parabrahma Temple, which is known for its unique traditions and rituals.',
            image: temple,
        },
    ];
    return (
        <>
            <div className="row">
                {carouselItems1.map((item, index) => (
                    <div key={index} className="col-lg-3 col-md-6 col-sm-12 mt-3 slider_element">
                        <div className="why_detail">
                            <Tilt>
                                <img className="mx-auto" src={item.image} alt={item.title} />
                                <p className="thing_to_do_title">{item.title}</p>
                                <p className="description">{item.description}</p>
                            </Tilt>
                        </div>
                    </div>
                ))}
            </div>

            <div className="row">
                {carouselItems2.map((item, index) => (
                    <div  key={index + "item2"} className="col-lg-3 col-md-6 col-sm-12 mt-3">
                        <div className="why_detail">
                            <Tilt>
                                <img className="mx-auto"  src={item.image} alt={item.title} />
                                <p className="thing_to_do_title">{item.title}</p>
                                <p className="description" >{item.description}</p>
                            </Tilt>
                        </div>
                    </div>

                ))}
            </div >
        </>
    );
}
export default Carousel;