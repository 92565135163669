import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css'
import hostMain from '../assets/image/hostImg.jpg'
import HostImgAni from './HostImgAni';
import host1 from './host1.jpg'
import host2 from './host2.jpg'
import host3 from './host3.jpg'

export default function WhyHere() {
    AOS.init({});

    return (
        <div className='about_sec'>
            <div className='sec-section'>
                <div className='row '>
                    <div className='col-lg-6'>
                        <div className='about_img'>
                            <img src={hostMain} className='img-fluid' />
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='about_detail '>
                            <div data-aos="fade-up"
                                data-aos-duration="3000">
                                <h2>Your Peace of Mind, Our Guarantee</h2>
                                <p className='mb-0'>At <span style={{fontWeight:900, color:'#000000'}}>Tranquil Thaamara</span>, your safety is our utmost concern. We have vigilant security guards ensuring a secure environment, and a 24-hour caretaker at your service, ready to fulfill your every needs. Your stay here is synonymous with comfort and luxury. Our accommodations are well-appointed and designed to make you feel at home. Whether you are here for business or leisure, we have everything you need to make your stay enjoyable. From our top-notch amenities to our delicious dining options, we have thought of everything to ensure you have a memorable stay. Our staff is friendly and dedicated to providing you with exceptional service. You can rest assured that your experience at Tranquil Thaamara will exceed your expectations.  <br /> </p>
                                <p>Book your stay with us today, call us now!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}