import React from 'react'
import blacknwhite from '../assets/image/ship.png'

import '../index.css'
// import styles from './centertextstyles.module.css'

function HowToReach() {
    return (
        <div className='pb-5'>
            <div name='begin' className="how_to_reach_banner_main">
                <div className='overflow-hidden'>
                    <figure>
                        <img src={blacknwhite} alt="banner" className="banner-image img-fluid" />
                    </figure>
                </div>
                <div className="about_how_to_reach_content">
                    <div className='container about_how_to_reach'>
                        <div >
                            <div>
                                <h2  >How to Reach </h2>
                              
                                <span>
                                    Nearest Airport

                                </span>
                                <p>The nearest airport to <span style={{fontWeight:900}}>Tranquil Thaamara</span> is Trivandrum International Airport (Thiruvananthapuram Airport), approximately 70 kilometers away.</p>
                             
                                <div >
                               
                                    From the Nearest Railway Station
                                
                                </div>
                                <p>
                                    Kollam Junction is the nearest major railway station to <span style={{fontWeight:900}}>Tranquil Thaamara</span>, situated approximately 4 kilometers away.

                                    For the most accurate and up-to-date information on transportation options and routes, it's advisable to contact us in advance. We can provide you with personalized guidance and assist in arranging transportation to ensure a smooth and hassle-free journey to the property..
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowToReach