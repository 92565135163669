import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'
import '../index.css'
import about from '../assets/image/about.png'

const SecondPart = () => {

    AOS.init({
        duration: 1000, // Animation duration
        // once: true, // Animation only happens once
    });
    return (
        <div className='about_sec'>
            <div className='sec-section'>
                <div className="row">
                    <div className='col-lg-6 '>
                        <div className='about_img mx-auto overflow-hidden'>
                            <img src={about} className='img-fluid' />
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='first-section'>
                            <div data-aos="fade-up"
                                data-aos-duration="3000">
                                <div className=''>
                                    <h2>An Exquisite Escape Awaits</h2>
                                </div>
                                <p className='paragraph' >
                                    Welcome to <span style={{fontWeight:900, color:'#000000'}}>Tranquil Thaamara</span>, a sanctuary where the soul meets serenity, nestled by the tranquil shores of Ashtamudi Lake. We invite you to embark on a journey through the myriad facets of <span style={{fontWeight:900, color:'#000000'}}>Tranquil Thaamara</span>, a place where modern luxury seamlessly harmonizes with Kerala's rich heritage.
                                    <br /><br />
                                    The name "Thaamara" translates to "Lotus" in Malayalam, and symbolizes the tranquil beauty of a lotus flower gracing the shores of Ashtamudi Lake. As you step into our sanctuary, you will be greeted by the soothing sound of the lake's gentle waves, and the fragrant aroma of the surrounding flora. <span style={{fontWeight:900, color:'#000000'}}>Tranquil Thaamara</span> is designed to be a retreat for your mind, body, and soul. Our luxurious rooms are adorned with locally sourced decor, reflecting Kerala's vibrant culture and heritage.
                                    <br /><br />
                                    It's a heaven where modern comfort effortlessly mingles with the unspoiled beauty of nature.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SecondPart